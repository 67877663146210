<style scoped>
@media print {
    body {
        margin: 0 !important;
        padding: 0 !important;
    }

    @page {
        margin: 0;
        /* size: 10cm 5cm; */
    }

    #print-section {
        z-index: 9999 !important;
        margin-left: -260px;
        margin-top: -70px;
        padding: 0 !important;
        margin-bottom: 0 !important;
        height: 100%;
    }
}
</style>
<template>
    <div>
        <v-container fluid class="px-0 d-print-none">
            <v-card outlined elevation="3">
                <v-card-text>
                    <v-data-table
                        class="black--text"
                        :headers="headers"
                        :search="search"
                        :items="dataBarcode"
                        :loading="loading"
                        :items-per-page="itemsPerPage"
                        :page.sync="page"
                        hide-default-footer
                        dense
                    >
                        <template v-slot:top>
                            <v-toolbar flat dense class="justify-center mb-5">
                                <v-row dense>
                                    <v-col class="pa-0 mr-2 d-none">
                                        <v-menu
                                            v-model="expiredDateMenu"
                                            :close-on-content-click="false"
                                            :nudge-left="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="expiredDate"
                                                    append-icon="mdi-calendar-month"
                                                    placeholder="expired date"
                                                    readonly
                                                    dense
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    outlined
                                                    single-line
                                                    hide-details
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                ref="picker"
                                                v-model="expiredDate"
                                                range
                                                v-on:change="getDataFilter"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="pa-0 mr-2 d-none">
                                        <v-menu
                                            v-model="manufactureDateMenu"
                                            :close-on-content-click="false"
                                            :nudge-left="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="manufacturingDate"
                                                    append-icon="mdi-calendar-month"
                                                    placeholder="manufacture date"
                                                    readonly
                                                    dense
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    outlined
                                                    single-line
                                                    hide-details
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                ref="picker"
                                                v-model="manufacturingDate"
                                                range
                                                v-on:change="getDataFilter"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="expiredDates"
                                            label="Expired Date"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listExpiredDate"
                                            item-value="expired_date"
                                            v-on:change="getDataFilter"
                                        >
                                            <template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .expired_date == ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.expired_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .expired_date == ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.expired_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-select
                                            v-model="manufacturedDates"
                                            label="Manufactured Date"
                                            dense
                                            outlined
                                            hide-details
                                            :items="listManufacturedDate"
                                            item-value="manufacturing_date"
                                            v-on:change="getDataFilter"
                                        >
                                            <template
                                                slot="selection"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .manufacturing_date ==
                                                            ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.manufacturing_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                            <template
                                                slot="item"
                                                slot-scope="data"
                                            >
                                                <span
                                                    v-if="
                                                        data.item
                                                            .manufacturing_date ==
                                                            ''
                                                    "
                                                    >all</span
                                                >
                                                <span v-else>{{
                                                    data.item.manufacturing_date.substring(
                                                        0,
                                                        10
                                                    )
                                                }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="searchMc"
                                            autocomple="off"
                                            placeholder="barcode MC"
                                            append-icon="mdi-magnify"
                                            :prepend-inner-icon="
                                                prependIconSearchMc
                                            "
                                            @click:append="searchDataMc"
                                            @click:prepend-inner="clearSearchMc"
                                            v-on:keyup.enter="searchDataMc"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="searchIc"
                                            autocomple="off"
                                            placeholder="barcode IC"
                                            append-icon="mdi-magnify"
                                            :prepend-inner-icon="
                                                prependIconSearchIc
                                            "
                                            @click:append="searchDataIc"
                                            @click:prepend-inner="clearSearchIc"
                                            v-on:keyup.enter="searchDataIc"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="pa-0 mr-2">
                                        <v-text-field
                                            outlined
                                            dense
                                            v-model="searchQr"
                                            autocomple="off"
                                            placeholder="Kode QR"
                                            append-icon="mdi-magnify"
                                            :prepend-inner-icon="
                                                prependIconSearchQr
                                            "
                                            @click:append="searchDataQr"
                                            @click:prepend-inner="clearSearchQr"
                                            v-on:keyup.enter="searchDataQr"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col class="pa-0">
                                        <download-csv
                                            :data="dataJsonToCsv"
                                            name="Data Barcode.csv"
                                        >
                                            <v-btn
                                                color="#61B15A"
                                                dark
                                                class="mb-2 text-subtitle-1"
                                                style="text-transform: unset !important"
                                            >
                                                <v-icon left
                                                    >mdi-file-download</v-icon
                                                >
                                                Unduh Data
                                            </v-btn>
                                        </download-csv>
                                    </v-col>
                                </v-row>
                            </v-toolbar>
                            <v-toolbar flat dense class="mb-5">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    autocomple="off"
                                    placeholder="Search..."
                                ></v-text-field>
                            </v-toolbar>
                            <div
                                class="d-flex mb-5 px-4 justify-space-between align-center"
                            >
                                <v-row>
                                    <v-col cols="2">
                                        <v-select
                                            v-model="itemsPerPage"
                                            :items="showPerPage"
                                            outlined
                                            dense
                                            label="items per page"
                                            v-on:change="changeItemPerPage"
                                        >
                                        </v-select>
                                    </v-col>
                                    <v-col align="right">
                                        <span class="mr-15 text-caption"
                                            >Page: {{ page }}</span
                                        >
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="prevPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-left
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            fab
                                            x-small
                                            depressed
                                            color="primary"
                                            class="mx-3"
                                            @click="nextPage"
                                        >
                                            <v-icon dark>
                                                mdi-chevron-right
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                        </template>

                        <template v-slot:item.manufacturing_date="{ item }">
                            <span v-if="item.manufacturing_date">
                                {{
                                    item.manufacturing_date
                                        | moment("YYYY/MM/DD")
                                }}
                            </span>
                        </template>

                        <template v-slot:item.expired_date="{ item }">
                            <span v-if="item.expired_date">
                                {{ item.expired_date | moment("YYYY/MM/DD") }}
                            </span>
                        </template>
                        <template v-slot:item.mc_code="{ item }">
                            <a
                                v-if="
                                    item.mc_code.trim().substring(0, 3) == 'MCR'
                                "
                                href="#"
                                @click="printBarcode(item.mc_code)"
                                >{{ item.mc_code }}</a
                            >
                            <span v-else>
                                {{ item.mc_code }}
                            </span>
                        </template>
                        <template v-slot:item.ic_code="{ item }">
                            <a
                                v-if="
                                    item.ic_code.trim().substring(0, 3) == 'ICR'
                                "
                                href="#"
                                @click="printBarcode(item.ic_code)"
                                >{{ item.ic_code }}</a
                            >
                            <span v-else>
                                {{ item.ic_code }}
                            </span>
                        </template>
                    </v-data-table>
                    <div
                        class="d-flex mt-5 px-4 justify-space-between align-center"
                    >
                        <v-row>
                            <v-col cols="2">
                                <v-select
                                    v-model="itemsPerPage"
                                    :items="showPerPage"
                                    outlined
                                    dense
                                    label="items per page"
                                    v-on:change="changeItemPerPage"
                                >
                                </v-select>
                            </v-col>
                            <v-col align="right">
                                <span class="mr-15 text-caption"
                                    >Page: {{ page }}</span
                                >
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="prevPage"
                                >
                                    <v-icon dark> mdi-chevron-left </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    x-small
                                    depressed
                                    color="primary"
                                    class="mx-3"
                                    @click="nextPage"
                                >
                                    <v-icon dark> mdi-chevron-right </v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
        </v-container>
        <div id="print-section" class="d-none d-print-inline-block">
            <barcode
                :value="barcodeRepack"
                textAlign="left"
                :height="180"
                :width="3"
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import VueBarcode from "vue-barcode";

export default {
    data: () => ({
        search: "",
        searchMc: "",
        searchIc: "",
        searchQr: "",
        prependIconSearchMc: "",
        prependIconSearchIc: "",
        prependIconSearchQr: "",
        loading: false,
        expiredDateMenu: false,
        expiredDate: [],
        expired_from: "",
        expired_to: "",
        manufactureDateMenu: false,
        manufacturingDate: [],
        manufacture_from: "",
        manufacture_to: "",
        listBarcodeMc: [],
        listBarcodeIc: [],
        listQrCode: [],
        dataJsonToCsv: [],
        manufacturedDates: "",
        listManufacturedDate: [],
        expiredDates: "",
        listExpiredDate: [],
        headers: [
            {
                text: "No QR Botol/Slv/Box",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "qr_code",
            },
            {
                text: "Manufacturing Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "manufacturing_date",
            },
            {
                text: "Batch",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "batch_no",
            },
            {
                text: "Expired Date",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "expired_date",
            },
            {
                text: "Barcode MC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "mc_code",
            },
            {
                text: "Barcode IC",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "ic_code",
            },
            {
                text: "Flag Terima",
                class: "text-subtitle-2 font-weight-bold black--text",
                value: "is_terima",
            },
        ],
        dataBarcode: [],
        page: 1,
        itemsPerPage: 10,
        showPerPage: [10, 50, 100, 250, 500, 750, 1000],
        barcodeRepack: "",
    }),
    methods: {
        printBarcode(code) {
            this.barcodeRepack = code;
            setTimeout(() => window.print(), 0);
        },
        ...mapActions({
            refreshAction: "auth/refresh",
        }),

        getDataFilter() {
            this.loading = true;
            this.expiredDateMenu = false;
            this.manufactureDateMenu = false;
            // if (this.expiredDate.length !== 0) {
            //   if (this.expiredDate[0] > this.expiredDate[1]) {
            //     this.expired_from = this.expiredDate[1] + "T00:00:00.000Z";
            //     this.expired_to = this.expiredDate[0] + "T23:59:59.000Z";
            //   } else {
            //     this.expired_from = this.expiredDate[0] + "T00:00:00.000Z";
            //     this.expired_to = this.expiredDate[1] + "T23:59:59.000Z";
            //   }
            // }
            // if (this.manufacturingDate.length !== 0) {
            //   if (this.manufacturingDate[0] > this.manufacturingDate[1]) {
            //     this.manufacture_from = this.manufacturingDate[1] + "T00:00:00.000Z";
            //     this.manufacture_to = this.manufacturingDate[0] + "T23:59:59.000Z";
            //   } else {
            //     this.manufacture_from = this.manufacturingDate[0] + "T00:00:00.000Z";
            //     this.manufacture_to = this.manufacturingDate[1] + "T23:59:59.000Z";
            //   }
            // }
            if (this.expiredDates !== "") {
                this.expired_from = this.expiredDates;
                this.expired_to =
                    this.expiredDates.substring(0, 10) + "T23:59:59.000Z";
            }
            if (this.manufacturedDates !== "") {
                this.manufacture_from = this.manufacturedDates;
                this.manufacture_to =
                    this.manufacturedDates.substring(0, 10) + "T23:59:59.000Z";
            }
            axios
                .get(
                    "v1/admin/master_data/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&expired_from=" +
                        this.expired_from +
                        "&expired_to=" +
                        this.expired_to +
                        "&manufacture_from=" +
                        this.manufacture_from +
                        "&manufacture_to=" +
                        this.manufacture_to +
                        "&mc_code=" +
                        this.searchMc +
                        "&ic_code=" +
                        this.searchIc +
                        "&qr_code=" +
                        this.searchQr
                )
                .then((response) => {
                    this.loading = false;
                    this.dataBarcode = response.data.data;
                    this.unduhData();
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            this.refreshAction(localStorage.getItem("refresh"));
                            this.getDataFilter();
                        }
                        console.clear();
                    }
                });
        },
        changeItemPerPage() {
            this.getDataFilter();
        },
        prevPage() {
            if (this.page === 1) {
                return;
            } else {
                this.page = this.page - 1;
                this.getDataFilter();
            }
        },
        nextPage() {
            if (this.dataBarcode === null) {
                return;
            } else {
                this.page = this.page + 1;
                this.getDataFilter();
            }
        },
        next(page) {
            this.page = page;
            this.getDataFilter();
        },
        searchDataMc() {
            if (this.searchMc === "") {
                this.clearSearchMc();
            } else {
                this.prependIconSearchMc = "mdi-close";
                this.dataBarcode = [];
                this.getDataFilter();
            }
        },
        searchDataIc() {
            if (this.searchIc === "") {
                this.clearSearchIc();
            } else {
                this.prependIconSearchIc = "mdi-close";
                this.dataBarcode = [];
                this.getDataFilter();
            }
        },
        searchDataQr() {
            if (this.searchQr === "") {
                this.clearSearchQr();
            } else {
                this.prependIconSearchQr = "mdi-close";
                this.dataBarcode = [];
                this.getDataFilter();
            }
        },
        clearSearchMc() {
            this.searchMc = "";
            this.prependIconSearchMc = "";
            this.dataBarcode = [];
            this.getDataFilter();
        },
        clearSearchIc() {
            this.searchIc = "";
            this.prependIconSearchIc = "";
            this.dataBarcode = [];
            this.getDataFilter();
        },
        clearSearchQr() {
            this.searchQr = "";
            this.prependIconSearchQr = "";
            this.dataBarcode = [];
            this.getDataFilter();
        },
        getDate() {
            this.getExpiredDate();
            this.getManufacturedDate();
        },
        async getExpiredDate() {
            const defaultSelect = { expired_date: "" };
            axios
                .get("v1/admin/master_data/getListDate?tipedate=EXPDATE")
                .then((response) => {
                    this.listExpiredDate = response.data.data;
                    this.listExpiredDate.push(defaultSelect);
                });
        },
        async getManufacturedDate() {
            const defaultSelect = { manufacturing_date: "" };
            axios
                .get("v1/admin/master_data/getListDate?tipedate=MANUFDATE")
                .then((response) => {
                    this.listManufacturedDate = response.data.data;
                    this.listManufacturedDate.push(defaultSelect);
                });
        },
        unduhData() {
            axios
                .get(
                    "v1/admin/master_data/list?limit=" +
                        this.itemsPerPage +
                        "&page=" +
                        this.page +
                        "&expired_from=" +
                        this.expired_from +
                        "&expired_to=" +
                        this.expired_to +
                        "&manufacture_from=" +
                        this.manufacture_from +
                        "&manufacture_to=" +
                        this.manufacture_to +
                        "&mc_code=" +
                        this.searchMc +
                        "&ic_code=" +
                        this.searchIc +
                        "&qr_code=" +
                        this.searchQr +
                        "&download=1"
                )
                .then((response) => {
                    var parseData = this.$papa.parse(response.data, {
                        header: true,
                    });
                    this.dataJsonToCsv = parseData.data;
                });
        },
    },
    created() {
        this.getDataFilter();
        this.getDate();
    },
    components: {
        barcode: VueBarcode,
    },
};
</script>
