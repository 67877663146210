<template>
    <div>
        <sidebar :id="id" :menu="menu" :submenu="submenu" />
        <v-container fluid>
            <LoadData />
        </v-container>
    </div>
</template>

<script>
import Sidebar from '../../components/Sidebar.vue'
import LoadData from '../../components/Laporan/masterBarcode/loadData.vue'

export default {
    name: "Master_Barcode",

    components: { Sidebar, LoadData },

    metaInfo: {
    title: "Rhea Admin",
    titleTemplate: "%s - Master Barcode",
    htmlAttrs: {
      lang: "id",
      amp: true,
    },
  },

  data: () => ({
    id: "0",
    menu: "Laporan",
    submenu: " -> Master Barcode",
  }),
}
</script>